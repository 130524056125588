<template>
  <div>
    <div :class="{ flex: !vertical }">
      <div class="flex-1" :class="{ 'mr-2': !vertical, 'mb-4': vertical }">
        <v-card class="h-full">
          <v-card-title>Capacity</v-card-title>
          <!-- <h2 class="text-lg">Capacity</h2> -->

          <v-card-text>
            <div class="flex" v-if="showRemaining">
              <div class="flex-1">Remaining:</div>
              <div class="flex-1">{{ value.capacity.remaining.sprint }}</div>
            </div>

            <div class="flex">
              <div class="flex-1">Total:</div>
              <div class="flex-1">{{ value.capacity.total.sprint }}</div>
            </div>

            <v-divider class="my-1" v-if="showAllocated" />

            <div class="flex" v-if="showAllocated">
              <div class="flex-1">Allocated:</div>
              <div class="flex-1">{{ value.capacity.total.sprint }}</div>
            </div>
          </v-card-text>
        </v-card>
      </div>

      <div class="flex-1">
        <v-card class="h-full">
          <v-card-title>Squad</v-card-title>
          <!-- <h2 class="text-lg">Squad</h2> -->

          <v-card-text>
            <div class="flex">
              <div class="flex-1">Members:</div>
              <div class="flex-1">{{ value.userCount }}</div>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SprintSquadOverview",
  props: {
    value: Object,
    vertical: { type: Boolean, default: false },
    showRemaining: { type: Boolean, default: false },
    showAllocated: { type: Boolean, default: false },
  },
};
</script>